@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import "../styles/variables";

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($purple, 0.4);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

html {
  min-height: 100%;
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 130px;
}

strong {
  font-weight: 900;
}

a,
.custom-control {
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.nav-tabs {
  .nav-item {
    text-align: center;
    width: 50%;
  }

  .nav-link {
    background-color: #e4e0e075;
    color: #5f4a8ba6;
    padding: 1rem;

    &:first-child {
      border-top-right-radius: 0;
    }
  }

  .active {
    font-weight: bold;
  }
}

.test-form {
  .custom-control {
    font-size: 1.09rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.character {
  &-icons {
    flex: none;
    padding: 1.25rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding-right: 0;
      width: 22%;
    }
  }

  &-body {
    width: 100%;

    &__status {
      font-size: 1.25rem;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }

    p {
      @include media-breakpoint-up(xl) {
        width: 60%;
      }
    }
  }
}

.card {
  border-radius: 1rem;
}

.card-header {
  background-color: $white;
  border-radius: 1rem;

  a {
    .card-title {
      font-size: 1.2rem;

      > svg {
        transition: 0.3s transform ease-in-out;
      }

      p {
        font-size: 1.5rem;
        line-height: 1rem;
        vertical-align: middle;
      }
    }
  }

  &.active {
    a {
      .card-title {
        > svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.card-accordion {
  .card {
    border: 0;

    .card-header {
      background-color: transparent;
      border-radius: 0;

      .card-icon {
        display: inline-block;
        text-align: center;
        width: 45px;
      }

      &:hover {
       // background-color: $yellow;
        text-decoration: none;
        transition: 0.5s background-color linear;
      }
    }

    .show {
      border-bottom: 1px solid $white;
    }

    .case-stat {
      margin-left: 3.5rem;
    }

    &:first-of-type {
      .card-header {
        &:hover {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
      }
    }

    &:last-of-type {
      .card-header {
        border: 0;

        &:hover {
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }

        &.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .card-body {
        border-top: 1px solid $lighter-gray;
      }
    }
  }
}

.has-error {
  label,
  legend {
    color: #df5a36;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  input {
    border: solid 1px #df5a36;
  }
}

.form-error {
  background: #df5a36;
  color: #fff;
  font-weight: bold;
  padding: 20px;
}

.question-wrap {
  padding: 5px 5px 5px 10px;

  &.question-incorrect {
    background: #df5a36;
    border-radius: 10px;
  }
}

label {
  width: 100%;
}

.correct + label,
.wrong + label {
  border-radius: 5px;
  font-weight: bold;
}

.correct + label {
  @extend .alert-success;
}

.wrong + label {
  @extend .alert-danger;
}
