/* You can add global styles to this file, and also import other style files */
@import url("https://use.typekit.net/mhu6wqm.css");
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/primeicons/primeicons.css';
@import "./styles/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
//@import "../node_modules/ngx-simple-modal/dist/styles/simple-modal.scss";
@import "./theme/global";
@import "./theme/modal";
