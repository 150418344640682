.modal {
  .modal-content {
    border-radius: 1rem;
    max-width: 750px;
    overflow: hidden;
    background-color: $white;
    color:$bluebg;
    .modal-body {
      height: 100%;
      max-height: 600px;
      overflow-y: auto;
      padding: 1.5rem 2rem;

      ol {
        padding-left: 1.5rem;
      }
    }
  }

  .modal-header {
    background-color:#568bdac0;
    justify-content: flex-end;
  }
}
.modal .modal-content {

  position: absolute!important;
  left: 35vw!important;
  top:27vh!important;
}