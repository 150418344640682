@import url("https://fonts.googleapis.com/css?family=Nanum+Gothic:800|Open+Sans:400,400i,700");
$yellow: #FDF907;
$white: #FFF;
$purple: #5F4A8B;
$dark-gray: #454045;
$lighter-gray: rgba(87, 81, 86, .14);
$bluebg: #525D7D;
.text-primary{
  color:#FEBA43!important;
}
.btn{
  text-transform: capitalize!important;
  border-radius: 20 20 20 20!important;
}
// Bootstrap variables override
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-family: "Nanum Gothic", "Helvetica Neue", Arial, sans-serif;
$enable-gradients: true;
$body-bg: $white;
$body-color: $white;
$line-height-base: 1.6;
$headings-font-weight: 800;
$nav-tabs-link-active-bg: $white;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1200px,
  xl: 1610px
);
$container2-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 750px,
  xl: 900px
);
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.35;
$font-size-lg: ($font-size-base * 1.1);
$hr-border-color: rgba($white, .5);
$navbar-dark-color: $white;
$nav-tabs-link-active-color: $bluebg;
$primary: $bluebg;
$secondary: #DF5A36;
$text-muted: rgba($dark-gray, .4);
$component-active-bg: lighten(#DF5A36, 20%);
